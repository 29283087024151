/*--------------------------------- Header ---------------------- */

/* .emp-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
} */

.emp-header-right {
  position: relative;
}

.emp-box-ppp {
  position: absolute;
  top: 74px; 
  left:89%;
  transform: translateX(-50%);
  background-color: white;
  border: 1px solid #ccc;
  padding: 20px;
  z-index: 1000;
  height: 307px;
  width: 290px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.emp-pop-data{
    margin-bottom: 11px;
    line-height: 52px;
    margin-left: 20px;
    font-size: 20px;
}
.emp-cls-bt{
  margin-left: 76px;
    /* margin-top: 40px; */
}
.emp-cls-bt button{
  border: none;
  border-radius: 5px;
  padding: 6px 30px 6px 30px;
}
.emp-header {
  grid-area: header;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px 0 30px;
  box-shadow: 0 6px 7px -3px rgba(0, 0, 0, 0.35);
}
.emp-menu-icon {
  display: inline;
}


/*---------------------------responsive--------------------------*/


@media screen and (min-width: 300px) and  (max-width: 350px){
  /* -----header---------- */
   .emp-box-ppp {     
    left: 50%;
  }
  .emp-pop-data {
    margin-bottom: -6px;
  }
}
@media screen and (min-width: 350px) and  (max-width: 400px){
  /* -----header---------- */
  .emp-box-ppp {     
   left: 59%;
 }
 .emp-pop-data {
   margin-bottom: -6px;
 }
 }
@media screen and (min-width: 400px) and  (max-width: 600px){
  /* -----header---------- */
  .emp-box-ppp {     
   left: 64%;
 }
 .emp-pop-data {
   margin-bottom: -6px;
 }
 }
@media screen and (min-width: 600px) and (max-width: 800px) {
  /* -----header---------- */
.emp-box-ppp {     
 left: 79%;
}
}
@media screen and (min-width: 800px) and (max-width:1024px) {
  /* -----header---------- */
  .emp-box-ppp {     
   left: 84%;
 }
}
@media screen and  (min-width:1025px) and  (max-width:1440px){
  /* -----header---------- */
  .emp-box-ppp {     
    left: 89%;
  }
}


.container-1 {
  display: flex;
  flex-direction: column;
  padding: 20px;
}
 
.content-1 {
  flex: 1;
  padding: 0 10px;
}
 
.box-1 {
  background-color: #f2f2f2;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
}
 
.header-1 {
  margin-bottom: 20px;
  /* left: 0; */
  display: flex;
  justify-content: end;
}
 
.section {
  margin-bottom: 20px;
}
 
.section-heading {
  margin-bottom: 10px;
}
 
.filters-1 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}
 
.select-1,
.select {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 14px;
}
 
.download-button {
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}
 
.download-button:hover {
  background-color: #0056b3;
}
 
.payslip-box,
.offer-letter-box {
  border: 1px solid #ccc;
  padding: 16px;
  border-radius: 8px;
}
 
@media (max-width: 768px) {
  .container-1 {
    padding: 10px;
  }
 
  .box-1 {
    padding: 15px;
  }
 
  .content-1 {
    padding: 0 5px;
  }
 
  .select-1,
  .select {
    font-size: 12px;
  }
 
  .download-button {
    padding: 6px 12px;
    font-size: 12px;
  }
}
 
 