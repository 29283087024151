.emp-polo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  color: black;
}

.emp-tex {
  display: flex;
}

.emp-electro h3 {
  margin: 10px 0 0 10px;
}

.emp-roll {
  padding: 0px;
}

.emp-data {
  background-color: rgb(167, 165, 165);
}

.emp-downs {
  /* border:1px solid black; */
  width: 700px;
  display: flex;
  gap: 1rem;
}

.emp-field_1 {
  display: flex;
  gap: 1rem;
  margin-bottom: 20px;
}

.emp-primary {
  margin-bottom: 1rem;
  width: 300px;
  padding-right: 20px;
}

.emp-secondary {
  margin-bottom: 1rem;
  width: 300px;
  padding-right: 20px;
}

.emp-third {
  margin-bottom: 1rem;
  width: 300px;
}

.emp-typo {
  display: flex;
  gap: 0px;
  margin-bottom: 20px;
}

.emp-diff {
  display: flex;
  gap: 2rem;
}

.emp-bot {
  display: flex;
  gap: 590px;
}

.emp-text {
  margin-bottom: 1rem;
  padding-left: 10px;
  padding-right: 20px;
  width: 20vw;
}

.emp-css-1ygcj2i-MuiTableCell-root {
  background-color: lightgray;
}

.emp-sticky-cell {
  background-color: white; 
}

@media (min-width: 600px) {
  .emp-box {
      justify-content: space-around;
      display: flex;
      flex: 1;
  }
}

@media (300px <= width <= 600px) {
  .emp-font {
      font-size: 15px;
  }
  .emp-typo {
      gap: 1rem;
  }
  .emp-text {
      width: 50vw;
  }
  .emp-field_1 {
      display: block;
      align-items: center;
  }
  .emp-downs {
      width: 300px;
  }
  .emp-primary {
      width: 300px;
  }
  .emp-field_1 > * {
      flex: 1;
      margin-right: 10px;
  }
  .emp-field_1 > *:last-child {
      margin-right: 0;
  }
  .emp-secondary {
      margin-bottom: 1rem;
      width: 300px;
      padding-right: 20px;
  }
  .emp-third {
      margin-bottom: 1rem;
      width: 300px;
  }
}

@media screen and (min-width: 300px) and  (max-width: 350px){
  .emp-bot {
      display: block;
  }
  .emp-typo {
      display: block;
  }
  button {
      margin-top: 20px;
  }
  .emp-bx {
      width: 290px;
  }
  .emp-bi {
      margin-left: -73px !important;
  }
}

@media screen and (min-width: 350px) and  (max-width: 400px){
  .emp-bot {
      display: block;
  }
  .emp-typo {
      display: block;
  }
  button {
      margin-top: 20px;
  }
  .emp-bx {
      width: 330px;
  }
}

@media screen and (min-width: 400px) and  (max-width: 600px){
  .emp-bot {
      display: block;
  }
  .emp-typo {
      display: block;
  }
  button {
      margin-top: 20px;
  }
  .emp-bx {
      width: 354px;
  }
}

@media screen and (min-width: 600px) and (max-width: 800px) {
  .emp-bot {
      gap: 150px;
  }
}

@media screen and (min-width: 800px) and (max-width:1024px) {
  .emp-bot {
      gap: 306px;
  }
}

@media screen and  (min-width:1025px) and  (max-width:1440px){
  .emp-bot {
      gap: 555px;
  }
}
