.hr-polo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  color: black;
}

.hr-roll {
  padding: 0px;
}

.hr-data {
  background-color: rgb(167, 165, 165);
}

.hr-downs {
  width:700px;
  display:flex;
  gap: 1rem;
}

.hr-field_1 {
  display:flex;
  gap:1rem;
  margin-bottom: 20px;
}

.hr-primary {
  margin-bottom:1rem;
  width:300px;
  padding-right: 20px;
}

.hr-secondary {
  margin-bottom:1rem;
  width:300px;
  padding-right: 20px;
}

.hr-third {
  margin-bottom: 1rem;
  width:300px;
}

.hr-typo {
  display: flex;
  gap: 2rem;
  margin-bottom: 20px;
  justify-content: space-between;
}

.hr-diff {
  display: flex;
  gap: 2rem;
}

.hr-text {
  margin-bottom: 1rem;
  padding-left: 10px;
  padding-right: 20px;
  width: 20vw;
}
.value {
  display: flex;
  gap: 2rem;
}

@media (min-width: 600px) {
  .hr-box {
      justify-content: space-around;
      display: flex;
      flex: 1;
  }
}

@media (300px <= width <= 600px) {
  .hr-font {
      font-size: 15px;
  }
  .value{
    display: block;
  }
  .like {
    margin-top: 20px;
  }
  .oops {
    margin-top: 20px;
  }
  
  .hr-typo {
      display: block;
      gap: 1rem;
  }

  .hr-text {
      width: 50vw;
  }
  
  .hr-field_1 {
      display: block;
      align-items: center;
  }
 
  .hr-downs {
      width:300px;
  }
  
  .hr-primary {
      width:300px;
  }
  
  .hr-field_1 > * {
      flex: 1;
      margin-right: 10px;
  }
 
  .hr-field_1 > *:last-child {
      margin-right: 0;
  }
  
  .hr-secondary {
      margin-bottom:1rem;
      width:300px;
      padding-right: 20px;
  }
  
  .hr-third {
      margin-bottom: 1rem;
      width:300px;
  }
}

