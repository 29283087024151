
#sidebar {
  grid-area: sidebar;
  height: 100%;
  background-color: #37474F; 
  overflow-y: auto;
  transition: all 0.5s ease; 
  -webkit-transition: all 0.5s ease;
  width: 250px;
  position: fixed;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.sidebar-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px 0 30px;
  margin-bottom: 18px;
}

.bars {
  width: 30px;
  color: white;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.bars:hover {
  transform: rotate(90deg);
}

.sidebar-brand {
  margin-top: 15px;
  font-size: 23px;
  font-weight: 700;
  color: #FFC107; 
  transition: color 0.3s ease;
}

.sidebar-brand:hover {
  color: #FFEB3B; 
}

.sidebar-list {
  padding: 0;
  list-style-type: none;
}

.sidebar-list-item {
  padding: 20px 20px 20px 54px;
  /* font-size: 20px; */
  transition: background-color 0.3s ease, border-right 0.3s ease, color 0.3s ease;
  color: #CFD8DC; 
}

.sidebar-list-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
  cursor: pointer;
  border-right: 4px solid #FFC107; /* Amber */
  padding-left: 50px;
  color: #FFEB3B; /* Yellow */
}

.sidebar-list-item > a {
  text-decoration: none;
  color: inherit;
  transition: color 0.3s ease;
}

.sidebar-list-item > a:hover {
  color: #FFEB3B; /* Yellow */
}

.sidebar-link {
  text-decoration: none;
  color: inherit;
  transition: color 0.3s ease;
}

.sidebar-link:hover {
  color: #FFEB3B; /* Yellow */
}

.sidebar-responsive {
  display: inline !important;
  position: absolute;
  z-index: 12 !important;
}

/* Animations and Additional Effects */
.sidebar-list-item {
  position: relative;
  overflow: hidden;
}

.sidebar-list-item::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.1);
  transition: left 0.3s ease;
}

.sidebar-list-item:hover::before {
  left: 0;
}


.disabled {
  pointer-events: none;
  opacity: 0.6;
  cursor: not-allowed;
}

@media screen and (max-width: 768px) {
  #sidebar {
      width: 210px;
      position: fixed;
      top: 0;
      left: -250px;
      transition: left 0.3s ease;
  }

  #sidebar.sidebar-responsive {
      left: 0;
  }

  .sidebar-title {
      padding: 10px 20px 0 20px;
      margin-bottom: 20px;
  }

  .sidebar-brand {
      font-size: 20px;
  }

  .sidebar-list-item {
      padding: 15px 15px 15px 40px;
      font-size: 18px;
  }

  .sidebar-list-item:hover {
      padding-left: 36px;
  }

  .bars {
      width: 25px;
  }
}

@media (769px <= width <= 990px) {
  #sidebar {
    width: 210px;
    position: fixed;
    top: 0;
    left: -250px;
    transition: left 0.3s ease;
}

#sidebar.sidebar-responsive {
    left: 0;
}

.sidebar-title {
    padding: 10px 20px 0 20px;
    margin-bottom: 20px;
}

.sidebar-brand {
    font-size: 20px;
}

.sidebar-list-item {
    padding: 15px 15px 15px 40px;
    font-size: 18px;
}

.sidebar-list-item:hover {
    padding-left: 36px;
}

.bars {
    width: 25px;
}
}

@media (991px <= width <= 1280px) {
  #sidebar {
    width: 210px;
    position: fixed;
    top: 0;
    left: -250px;
    transition: left 0.3s ease;
}

#sidebar.sidebar-responsive {
    left: 0;
}

.sidebar-title {
    padding: 30px 20px 0 20px;
    margin-bottom: 20px;
}

.sidebar-brand {
    font-size: 20px;
}

.sidebar-list-item {
    padding: 15px 15px 15px 40px;
    font-size: 18px;
}

.sidebar-list-item:hover {
    padding-left: 36px;
}

.bars {
    width: 25px;
}
}


.menu-icon {
  display: flex;
  align-items: center;
}
 
.icon {
  font-size: 30px;
  cursor: pointer;
  margin-right: 10px;
}


.office-container {
  display: flex;
  align-items: center;
  padding: 5px;
  transform: translateX(-20px);
  opacity: 0;
  animation: slideIn 0.5s forwards;
}

.office-name {
  margin-left: 5px;
  font-weight: 600;
  font-size: 20px;
  color: #37474F;
  transform: translateX(-20px);
  opacity: 0;
  animation: slideIn 0.5s forwards;
}

@keyframes slideIn {
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

 
 