.emp-Main-container{
  grid-area: main;
  overflow-y: auto;
  padding: 20px 20px;
  color: #0d0d0d;
}

body {
  font-family: Arial, sans-serif;
}

h3 {
  color: #333;
  margin-bottom: 20px;
  text-align: left;
}

.emp-container {
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-top: 50px;
}

.emp-header {
  margin-bottom: 30px;
  text-align: left;
  margin-top: 10px;
}

.emp-card-container {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.emp-card {
  background-color: #bad7d7;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px; 
  padding: 20px;
  text-align: center;
  width: 400px;
  transition: all 0.3s ease; 
}

.emp-card:hover {
  transform: translateY(-5px); 
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.emp-card h6 {
  font-size: 16px;
  margin-bottom: 10px;
}

.emp-card h2 {
  font-size: 28px;
  color: #333;
}

.emp-icon1 {
  font-size: 40px;
  color: #007bff;
}

.emp-content {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 50px;
}

.emp-chart {
  width: 45%;
  height: 52vh;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.emp-chart-2 {
  width: 45%;
  height: 52vh;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.polarchart {
  max-height: 300px; 
}

.emp-chart h6 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 18px;
}

.emp-chart-2 h6 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 18px;
}

@media (max-width: 768px) {
  .emp-card-container {
    flex-direction: column;
    align-items: center;
  }

  .emp-card {
    margin: 10px 0;
    width: 90%;
  }

  .emp-content {
    flex-direction: column;
    align-items: center;
  }

  .emp-chart {
    width: 90%;
    margin-bottom: 20px;
  }

  .emp-chart-2 {
    width: 90%;
    margin-bottom: 20px;
  }
}

@media (300px <= width <= 770px) {
  .emp-chart-2 {
    height: 400px;
    width: 90%;
  }
}

@media (771px <= width <= 1540px) {
  .emp-chart-2 {
    height: 400px;
    width: 45%;
  }
  .emp-chart {
    height: 400px;
  }
}