body {
    overflow-x: hidden;
  }
 
  .super-total {
    grid-area: main;
  }
 
  .super-dashboard-container {
    /* padding: 20px; */
    max-width: 100%;
    cursor: pointer;
    /* background-color:#ddd; */
  }
 
  .super-cards-container {
    display: flex;
    flex-wrap: wrap;
  }
 
  .super-card {
    flex: 1 0 calc(25% - 20px);
    border-radius: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 10px;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }
 
  .super-card:hover {
    transform: scale(1.05);
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  }
 
  .super-charts-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 15px;
  }
 
  .super-chart-container {
    width: 48%;
  }
 
  .super-chart {
    width: 100%;
    height: 300px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
   
  }
 
  .super-data-grid {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    padding: 10px; /* Reduce padding */
    margin-bottom: 20px;
    color: black;
   
    overflow-x: auto; /* Enable horizontal scrolling */
  }
 
  .super-data-grid table {
    width: auto; /* Set width to auto */
    min-width: 100%; /* Ensure minimum width */
    border-collapse: collapse;
  }
 
  .super-data-grid th,
  .super-data-grid td {
    padding: 10px;
    border: 1px solid #ddd;
    white-space: nowrap; /* Prevent text wrapping */
  }
 
  .datasuper--grid h2 {
    margin-bottom: 10px;
  }
 
  .super-data-grid table {
    width: 100%;
    border-collapse: collapse;
  }
 
  .super-data-grid th,
  .super-data-grid td {
    padding: 10px;
    border: 1px solid #ddd;
  }
 
  .super-data-grid th {
    background-color: #f2f2f2;
  }
  .super-footer {
    background-color: black;
    color: #ffffff;
    padding: 20px;
    text-align: center;
}
 
.super-footer-content {
    display: flex;
    align-items: flex-start; /* Align items to the start of the cross axis (vertically) */
    justify-content: center;
}
 
.super-footer-text {
    margin-right: 10px;
    font-size: 18px;
    margin-top: -2px; /* Adjust margin to move text up */
}
 
.super-footer-icon {
    font-size: 24px;
    margin-right: 5px;
    margin-top: -4px; /* Adjust margin to move icon up */
}
 
.super-footer-icon:last-child {
    margin-right: 0; /* Remove margin from last icon */
}
 
 
  @media (max-width: 468px) {
    .super-card {
      flex: 1 0 calc(50% - 20px);
    }
 
 
 
  /*--------------------------*/
 
    .super-chart-container {
      width: 100%;
      margin-bottom: 20px;
    }
 
    .super-charts-container {
      justify-content: space-around;
    }
  }
 
  @media (max-width: 768px) {
    .super-charts-container {
      flex-direction: column;
      align-items: center;
    }
 
    .super-chart-container {
      width: 100%;
      margin-bottom: 20px;
    }
  }
 
.form-container {
  max-width: 1000px;
  margin: 50px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-top: 40px;
  box-shadow: 0px 0px 10px rgba(0.1, 0.1, 0.1, 0.1);
}
.header-right1{
  display: flex;
}
.add-icon{
  cursor: pointer;
}
 
.super-add{
  margin-top: 2px;
  padding: 2px;
}
 
.form-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
 
.form-group {
  flex: 0 0 45%;
  margin-bottom: 20px;
  padding: 5px;
}
 
.form-label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}
 
.form-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}
 
.submit-button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
 
}
 
.submit-button:hover {
  background-color: #0056b3;
}
.back-button{
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-left: 30px;
}
 
 
.client-heading {
  margin-top: 40px;
  margin-left: 50px;
}
 
@media screen and (max-width: 768px) {
  .form-group {
    flex: 0 0 100%;
  }
}
 
 
.super-card {
  width: 300px;
  height: 200px;
  margin-bottom: 20px;
  margin-left: 40px; 
  margin-top: 60px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #6692b0;
  color: #fff;
}
 
.super-card h3 {
  margin-bottom: 5px;
}
 
.super-card p {
  margin-bottom: 0;
}
 
.super-icon {
  font-size: 30px;
}

.cursor {
  margin-left: 20px;
  cursor: pointer;
  
}
 
@media screen and (max-width: 768px) {
  .super-card {
    width: calc(50% - 20px);
    margin-left: 8px;
    margin-top: 2px;
  }
 
  .super-card:nth-child(odd) {
    margin-right: 20px;
  }
  .header-right1{
    margin-bottom: 30px;
    margin-top: 15px;
  }
}
 
