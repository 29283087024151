body {
    font-family: Arial, sans-serif;
    background-color: #f7f7f7;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
 
  .forgot-container {
    width: 90%;
    max-width: 500px;
    background: #fff;
    margin: auto;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    text-align: center;
    flex-grow: 1;
    margin-top: 120px;
  }
 
  .forgot-container h2 {
    margin-bottom: 20px;
    color: #333;
    font-size: 24px;
  }
 
  .description {
    margin-bottom: 20px;
    color: #666;
  }
 
  .form-group {
    margin-bottom: 15px;
    text-align: left;
  }
 
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #555;
  }
 
  .form-group input,
  .otp-container input {
    width: calc(100% - 20px);
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
  }
 
  .otp-container {
    display: flex;
    justify-content: space-between;
    gap: 5px;
  }
 
  .otp-container input {
    width: 40px;
    text-align: center;
    font-size: 18px;
  }
 
/* .rest-bttn{
    width: 50%;
    padding: 10px;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    color: white;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s;
} */
 
  /* Responsive Styles */
  @media (max-width: 768px) {
    .forgot-container {
      padding: 15px;
    }
 
    .otp-container input {
      width: 35px;
    }
  }
 
  @media (max-width: 480px) {
    .forgot-container {
      padding: 10px;
    }
 
    .otp-container input {
      width: 30px;
    }
  }