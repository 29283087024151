/* ------------------------------sidebar------------------------ */
#sidebar {
    grid-area: sidebar;
    height: 100%;
    background-color: black;
    overflow-y: auto;
    transition: all 0.5s; /* Default transition */
    -webkit-transition: all 0.5s;
    /* max-width: 220px; */
    width: 250px;
    position: fixed;
  }
  .sidebar-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 30px 0px 44px;
    margin-bottom: 30px;
  }
  .bars {
    width: 30px;
    color: white;
  }
  .sidebar-brand {
    margin-top: 15px;
    font-size: 20px;
    font-weight: 700;
    color: #FFC107; 
  }
  .sidebar-list {
    padding: 0;
    list-style-type: none;
  }
  .sidebar-list-item {
    padding: 20px 20px 20px 54px;
    font-size: 18px;
  }
  .sidebar-list-item:hover {
    background-color: rgba(255, 255, 255, 0.2);
    cursor: pointer;
      border-right: 4px solid white;
  }
  .sidebar-list-item > a {
    text-decoration: none;
    color: hsl(0, 0%, 100%);
  }
  .sidebar-link  {
    text-decoration: none;
    color: hsl(0, 0%, 100%);
  }
  .sidebar-responsive {
    display: inline !important;
    position: absolute;
    
    z-index: 12 !important;
  }
  .icon {
    cursor: pointer;
  }
    #sidebar {
      display: none;
    }

    .notification-wrapper {
      position: relative;
    }

    .header-right {
      display: flex;
      align-items: center; 
    }
    
    .icon {
      font-size: 28px; 
      margin-right: 10px; 
      cursor: pointer;
    }
    
    .notification-wrapper {
      position: relative;
    }
    
    .notification-panel {
      position: absolute;
      top: calc(100% + 20px); 
      right: 0;
      z-index: 1000; 
      background-color: #657f8a; 
      box-shadow: 0 2px 4px rgba(23, 21, 21, 0.1); 
      border-radius: 8px; 
      padding: 20px; 
      min-width: 300px; 
      gap: 1.5rem;
      display: flex;
      flex-direction: column;
    }
    
    .notifications-container {
      max-height: 500px; 
      overflow-y: auto; 
    }
  
    .user-name {
      font-weight: bold; 
      color: rgb(48, 48, 38);
      font-size: 18px;
    }
    .notification-count {
      position: absolute;
      top: 0;
      right: 0;
      background-color: red;
      color: white;
      border-radius: 50%;
      padding: 3px 6px;
      font-size: 12px;
    }

    .notification-link {
      color: white;
    }

/* Modal backdrop */
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal content */
.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  max-width: 300px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

/* Modal title */
.modal-title {
  margin-bottom: 15px;
  font-size: 1.5em;
  color: #333;
}

/* Modal footer */
.modal-footer {
  text-align: center;
  margin-top: 20px;
}

/* Button styles */
.btn {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
}

.btn-secondary {
  background-color: #6c757d;
  color: white;
  margin-right: 10px;
  transition: background-color 0.3s ease;
}

.btn-secondary:hover {
  background-color: #5a6268;
}

.btn-primary {
  background-color: #007bff;
  color: white;
  transition: background-color 0.3s ease;
}

.btn-primary:hover {
  background-color: #0056b3;
}



