.custom-input:focus,
.custom-select:focus {
    outline: none;
    box-shadow: none; 
    border-color: #504949 ; 
}
.hr-empLabel{
    font-weight: 700 !important;
    font-size: 15px;
}
.hr-custom-input {
  margin: 5px 0;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
/* .... */
.hr-form-control{
    width: 70%;
    height: 50%;
  }
  /* .empLabel{
    font-size: 30px !important;
  } */
  
  .dropdown {
    width: 200px; /* Adjust the width as needed */
    padding: 8px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    margin-left: 20px;
  }
  
  .dropdown:focus {
    outline: none;
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  
  .dropdown option {
    padding: 8px;
  }
  
  .dropdown-button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 6px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    font-weight: bold;
    font-size: 16px;
    margin-left: 20px;
  }
  
  .dropdown-button:hover {
    background-color: #0056b3;
  }
  



/* @media only screen and (min-width:300px ) and ( max-width:1440px){

    .empLabel{
      font-weight: 700 !important;
    }
    .form-control{
      width: 100%;
      height: auto;
    }
    
  } */
