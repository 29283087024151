/* Header  */
.header {
  grid-area: header;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px 0 30px;
  box-shadow: 0 6px 7px -3px rgba(0, 0, 0, 0.35);
}
 
.menu-icon {
  display: inline;
  cursor: pointer;
}
/* End Header  */


.main-title {
  margin-bottom: 20px;
  text-align: center;
}

.main-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 40px;
}

.card {
  width: 280px; 
  height: 120px; 
  margin: 10px;
  padding: 10px;
  border: 1px solid #0aa5de;
  border-radius: 10px;
  text-align: center;
  transition: all 0.3s ease;
  cursor: pointer;
}

.card:hover {
  transform: translateY(-5px); 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
}

.card-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.card h4 {
  font-size: 18px; 
  margin-top: 10px;
}

.card_icon {
  font-size: 28px; 
  margin-right: 5px; 
}


.charts {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  margin-top: "50px" !important;
}

.chart-container {
  flex: 1;
  margin-right: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.bottam {
  position: relative;
}

.text-primary {
  color: #007bff !important;
}

.chart-container h4 {
  text-align: center;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  padding: 5px 15px;
  border-radius: 5px;
}

.data-gender-container {
  display: flex;
  margin-top: 20px;
}

.gender-chart {
  flex: 1;
  margin-right: 20px;
}

.data-grid {
  flex: 2;
  overflow-y: auto;
  max-height: 350px;
}

.data-grid table {
  width: 100%;
}

.data-grid th,
.data-grid td {
  text-align: center;
}

.data-grid th {
  background-color: #263043;
  color: white;
}

@media (599px <= width <= 900px) {
  .charts {
    display: block;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .chart-container {
    margin-top: 50px;
  }

  .data-gender-container {
    display: block;
    margin-top: 20px;
  } 

  .data-grid {
    margin-top: 100px;
  }

}
@media (300px <= width <= 600px) {
  .charts {
    display: block;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .chart-container {
    margin-top: 50px;
  }
  .data-gender-container {
    display: block;
    margin-top: 20px;
  }
  .data-grid {
    margin-top: 100px;
  }
}