.polo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  color: black;
}

.roll {
  padding: 0px;
}
 
.data {
  background-color: rgb(167, 165, 165);
}
.downs{
  width:700px;
  display:flex;
  gap: 1rem;
}
.field_1{
  display:flex;
  gap:1rem;
  margin-bottom: 20px;
}
 .primary{
  margin-bottom:1rem;
  width:300px;
  padding-right: 20px;
 }
 .secondary{
  margin-bottom:1rem;
  width:300px;
  padding-right: 20px;
 }
 .third{
  margin-bottom: 1rem;
  width:300px;
 }

 .typo {
  display: flex;
  gap: 2rem;
  margin-bottom: 20px;
  justify-content: space-between;
 }
 .diff {
  display: flex;
  gap: 2rem;
 }
 
 .text {
  margin-bottom: 1rem;
  padding-left: 10px;
  padding-right: 20px;
  width: 20vw;
 }

@media (min-width: 600px) {

  .box {
    justify-content: space-around;
    display: flex;
    flex: 1;
  }

}

@media (300px <= width <= 600px) {
 
  .font {
    font-size: 15px;
  }
  .typo {
    display: block;
    gap: 1rem;
   }

   .text {
    width: 50vw;
   }
   
  .field_1 {
    display: block;
   /* flex-wrap: wrap; */
    align-items: center;
   
  }
 
  .downs{
 
    width:300px;
  }
  .primary{
    width:300px;
  }
  .field_1 > * {
    flex: 1;
    margin-right: 10px;
  }
 
  .field_1 > *:last-child {
    margin-right: 0;
  }
  .secondary{
    margin-bottom:1rem;
    width:300px;
    padding-right: 20px;
   }
   .third{
    margin-bottom: 1rem;
    width:300px;
   }
}