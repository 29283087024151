  .hr-main--contain {
    grid-area: main;
    padding: 10px;
  }


  .hr-card--container {
    display: flex;
    gap: 15px;
    justify-content: space-between;
    grid-area: main;
    padding: 20px 20px;
  }
  
  .hr-card {
    flex: 1;
    width: 100%;
    margin-bottom: 20px;
    background: #dde6ed;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    transition: 0.5s ease-in-out;
  }
  
  .hr-card:hover {
    transform: scale(1.04);
    cursor: pointer;
  }
  .hr-chart{
    display: flex;
    justify-content: space-between;
    padding: 20px;
  }
  .hr-chart_1 {
    width: 48%;
    height: 50vh;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  }
  
  .hr-chart_2 {
    width: 48%;
    height: 50vh;
    padding-top: 50px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  }
  .hr-chart_3 {
    width: 35%;
    height: 48vh;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  }
  .hr-chart_4 {
    width: 35%;
    height: 48vh;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  }

  .hr-box {
    display: flex;
    /* padding-top: 40px; */
    justify-content: space-between;
    gap: 2rem;
    margin: 20px;
  }
  .hr-hr-box_4 {
    flex-wrap: wrap;
    gap: 15px;
    /* margin-top: 0rem; */
    justify-content: center;
  }
  .hr-box_5 {
    flex: 1;
    width: 45vh;
    margin-bottom: 20px;
    margin-top: 24px;
    background: #dde6ed;;
    border-radius: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    transition: 0.5s ease-in-out;
  }
  .hr-box_5:hover {
    transform: scale(1.04);
    cursor: pointer;
  }
  
  .hr-table-container {
    max-width: 100%;
    margin: 0 auto;
    overflow-y: auto;
    overflow-x: auto;
    max-height: 300px; 
    margin-bottom: 50px;
    margin-top: 20px;
    padding: 10px;
  }
  
  .hr-responsive-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
  }
  
  .hr-responsive-table th,
  .hr-responsive-table td {
    padding: 10px;
    border: 5px solid #ddd;
    text-align: left;
  }

  
  
  @media only screen and (min-width: 768px) {
    .hr-dashboard-header {
      padding: 20px 40px;
    }
    .hr-search-box {
      margin-right: 40px;
    }
  
    .hr-search-box input {
      padding: 12px;
      font-size: 18px;
    }
  
    .hr-dashboard-icons {
      gap: 30px;
    }
  }
  

  @media only screen and (min-width: 1024px) {
    .hr-dashboard-icons {
      gap: 40px;
    }
  }

  @media (290px <= width <= 374px) {

    .hr-dashboard-header h1 {
      font-size: 20px;
      align-items: center;
      justify-content: center;
    }
    .hr-card--container {
        display: block;
        width: 85%;
        margin-left: 20px;
    }
    .hr-header-icons{
      display: none;
    }
    .hr-chart {
      display: block;
      padding-top: 20px;
    }

    .hr-chart_1 {
      width: 100%;
      height: 55vh;
    }
    .hr-chart_2 {
      width: 100%;
      margin-top: 50px;
      height: 80vh;
    }
    .hr-chart_3 {
      width: 100%;
      margin-bottom: 40px;
      height: 80vh;
    }
    .hr-chart_4 {
      width: 100%;
      margin-bottom: 40px;
      height: 80vh;
    }
    .hr-box {
      display: block;
    }
    .hr-box_5 {
      width: 100%;
    }
    .hr-box_4 {
        width: 100%;
    }
    .hr-responsive-table th,
    .hr-responsive-table td {
      
      box-sizing: border-box;
    }
  
    .hr-responsive-table th {
      text-align: center;
    }
  }
 

  @media (375px <= width <= 600px) {
    .hr-dashboard-header h1 {
      font-size: 20px;
      align-items: center;
      justify-content: center;
    }
    .hr-card--container {
        display: block;
        width: 85%;
        margin-left: 20px;
    }
    .hr-header-icons{
      display: none;
    }
    .hr-chart {
      display: block;
      padding-top: 20px;
    }
    .hr-chart_1 {
      width: 100%;
      height: 60vh;
    }
    .hr-chart_2 {
      width: 100%;
      margin-top: 50px;
      height: 55vh;
    }
    .hr-chart_3 {
      width: 100%;
      margin-bottom: 40px;
    }
    .hr-chart_4 {
      width: 100%;
      margin-bottom: 40px;
      height: 52vh;
    }
    .hr-box {
      display: block;
    }
    .hr-box_5 {
      width: 100%;
    }
    .hr-box_4 {
        width: 100%;
    }
    .hr-responsive-table th,
    .hr-responsive-table td {
      
      box-sizing: border-box;
    }
  
    .hr-responsive-table th {
      text-align: center;
    }
  }

@media (928px <= width <= 1280px) {

  .hr-chart{
    display: flex;
    justify-content: space-between;
  }

  .hr-chart_1 {
    width: 48%;
    height: 58vh;
  }

  .hr-chart_2 {
    width: 48%; 
    height: 58vh;
  }

  .hr-chart_4 {
    width: 40%;
    height: 55vh;
  }

  .hr-chart_3 {
    width: 35%;
    height: 55vh;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  }

}  

@media (601px <= width <= 927px) {
  .hr-chart_4 {
    width: 40%;
    height: 38vh;
  }
  .hr-chart_3 {
    width: 40%;
    height: 38vh;
  }
  .hr-chart_1 {
    width: 48%;
    height: 38vh;
  }

  .hr-chart_2 {
    width: 48%; 
    height: 38vh;
  }
}


@media (300px <= width <= 654px) {
  .dot {
    display: block;
    flex-direction: column;
  }
  .gggg {
    justify-content: center;
    align-items: center;
    display: flex;
  }
}