.custom-input:focus,
.custom-select:focus {
    outline: none;
    box-shadow: none;
    border-color: #504949 ;
}
.empLabel{
    /* font-size: 20px !important; */
    font-weight: 500 !important;
}
 
.custom-input, .custom-select{
    border-color: #baaeae ;
    padding: 10px ;
}
.persDet{
    text-decoration: none;
    color: inherit;
}
/* .... */
.form-control{
    width: 60%;
    height: 50%;
  }
  .empLabel{
    font-size: 20px !important;
  }
 
  .is-invalid {
    border-color: red;
  }
  
  .invalid-feedback {
    color: red;
    font-size: 0.875em;
    margin-top: 0.25em;
  }
  
  
/* @media only screen and (min-width:300px ) and ( max-width:1440px){
 
    .empLabel{
      font-size: 20px !important;
      font-weight: 700 !important;
    }
    .form-control{
      width: 100%;
      height: auto;
    }
   
  } */