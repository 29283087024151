a {
    text-decoration: none;
    color: black;
  }
  
  .hori {
    display: flex;
    font-size: 20px;
    cursor: pointer;
    /* padding: 10px; */
    color: black;
    margin-bottom: 20px;
    gap: 5rem;
    z-index: 1;
    box-shadow: 0 2px 5px -2px rgba(0, 0, 0, 0.35);
  }
   
  .active {
    font-weight: bold;
  }
   
  .active::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background-color: red;
    transition: width 1s ease;
  }
   
  .active:hover::after {
    width: 100%;
   
  }

  .School {
    display: flex;
    justify-content: space-between;
  }

  .don {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  @media (600px <= width <= 900px) {
    .School {
      display: block;
    }
    .plant {
      margin-bottom: 30px;
      display: flex;
    }
  }

  
  @media (300px <= width <= 599px) {
    .School {
      display: block;
    }
    .plant {
      margin-bottom: 30px;
      display: flex;
    }
  }


  @media (max-width: 600px) and (min-width: 300px) {
    .hori {
      display: flex;
      font-size: 20px;
      cursor: pointer;
      padding: 15px;
      color: black;
      margin-bottom: 20px;
      gap: 2rem; 
      z-index: 1;
      box-shadow: 0 2px 5px -2px rgba(0, 0, 0, 0.35);
    }
  }
  
@media (300px <= width <= 425px) {
  .month-search {
    margin-top: 15px;
  }
}  

@media (min-width: 300px) and (max-width: 465px) { 
  .don {
    display: block;
  }
  .month-search {
    margin: 10px;
  }
}

@media (300px <= width <= 500px) {
  .mass {
    margin-top: 20px;
  }
}
