.main-container {
  grid-area: main;
  overflow-y: auto;
  /* color: rgba(248, 246, 246, 0.95); */
  margin-bottom: 60px;   
  margin: 10px;
  ; 
}
.Home {
  display: flex;
  /* height: 70px; */
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

  .dropdowns-container {
    display: flex;
    align-items: center;
    flex-grow: 1; /* Allow flex container to grow and take available space */
    margin-right: 20px;
}
  .location-dropdown,
  .department-dropdown {
    margin-right: 20px;
  }
  .location-dropdown label,
  .department-dropdown label {
    font-size: 16px;
    color: #333333;
    margin-right: 10px;
  }
 
  .location-dropdown select,
.department-dropdown select {
  padding: 10px;
  border: 1px solid #cccccc;
  border-radius: 10px;
  font-size: 16px;
  color: #333333;
  width: 200px; /* Set your preferred width for the location dropdown */
}
  /* Search Box and Add Button */
  .search-box,
  .add-button {
    display: flex;
    align-items: center;

  }
  .search-box input {
    padding: 8px;
    margin-right: -1px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;

  }
  .search-box button {
    padding: 8px 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    outline: none;
   
  }
  .search-box button:hover {
    background-color: #0056b3;
  }
  .add-button {
    cursor: pointer;
    background: #0dba24; /* Green color */
    color: #fff; /* White text color */
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    align-items: center;
    margin-right: 10px;
    margin-left: 70px;
    margin-top: 15px;
    height: 20px; /* Add margin if needed */
    width: 40%;  
  }
  .add-button:hover {
    background: #c1d2e3; /* Darker green color on hover */
  }
  /* Your existing CSS styles */

.button {
  cursor: pointer;
  background-color: #4caf50; /* Green color */
  color: #ffffff; /* White text color */
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  margin-right: 50px;
  margin-left: 30px;
  margin-top: 15px;
  height: 20px; /* Add margin if needed */
 
}
.button:hover {
  background-color: #45a049; /* Darker green color on hover */
}
  .popup-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border: 1px solid #ccc;
    z-index: 999;
  }
  .close-button {
    cursor: pointer;
    margin-top: 10px;
    text-align: center;
    color: #007bff;
  }
  /* Your existing CSS styles */
  .scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #007bff;
    color: #fff;
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  .scroll-to-top:hover {
    background-color: #0056b3;
  }
  .scroll-to-top-icon {
    font-size: 24px;
  }
  /* Add your other styles below */
  .popup-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff; /* White background color */
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 6px 7px -3px rgba(0, 0, 0, 0.35);
  z-index: 999;
  width: 300px; /* Set your preferred width */
  }
  .popup-content {
  margin-bottom: 15px;
  }
  .input-group {
  margin-bottom: 15px;
  }
  .input-group label {
  display: block;
  margin-bottom: 5px;
  color: #333; /* Text color for labels */
  }
  input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  }
  .close-button {
  cursor: pointer;
  margin-top: 10px;
  text-align: center;
  color: #007bff; /* Blue color for close button */
  }
  .close-button:hover {
  color: #0056b3; /* Darker blue color on hover */
  }
  /*Add Attendance details*/
  .header1 {
    color: #000;
    padding: 10px;
    text-align: left; 
    border-bottom: 1px solid #2e0bcb; 
    box-shadow: 0 6px 7px -3px rgba(0, 0, 0, 0.35);
    background-color: rgb(255, 255, 255);
    font-size: x-large;
    /* margin-bottom: 30px; */

  }
  /*end*/
 /* Media query for small screens */
@media (max-width: 500px) {
  .Home {
    flex-direction: row; /* Change flex direction to column on small screens */
    height: auto; /* Auto height on small screens */
    align-items: flex-start; /* Align items to the start on small screens */
  }
  .field {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
 
  .field > * {
    flex: 1;
    margin-right: 10px;
  }
 
  .field > *:last-child {
    margin-right: 0;
  }
  
.Home > * {
  flex: 1;
  margin-right: 10px; 
}

.Home > *:last-child {
  margin-right: 0;
}

  .location-dropdown,
  .department-dropdown,
  .search-box,
  .add-button {
    margin-bottom: 10px; /* Add spacing between elements on small screens */
    flex-basis: 100%; /* Full width on small screens */
  }

  .location-dropdown,
  .department-dropdown {
    margin-right: 0; /* Remove right margin on small screens */
  }

  .search-box {
    width: 100%; /* Full width for the search box on small screens */
  }

    .dropdowns-container{
      display: block;
    }
  }
  /* .electro {
    padding: 30px;
  } */
   
  .polo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
    color: black;
  }
   
  .roll {
    padding: 0px;
  }
   
  .data {
    background-color: rgb(167, 165, 165);
  }
   
  /* Admin.css */
   
   
   
  /* Media query for smaller screens */
  @media (min-width: 600px) {
   
    .box {
      justify-content: space-around;
      display: flex;
      flex: 1;
    }
  }
   
  @media (300px <= width <= 600px) {
   
    .font {
      font-size: 15px;
    }
    .field {
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
   
    .field > * {
      flex: 1;
      margin-right: 10px;
    }
   
    .field > *:last-child {
      margin-right: 0;
    }
  }
 
   