.form-container {
    display: flex;
    flex-wrap: wrap; 
    gap: 1rem; 
    padding: 1rem;
  }
 
  .form-group {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-width: 250px; 
  }
 
  .form-label {
    margin-bottom: 0.5rem;
  }
 
  .form-input {
    padding: 0.5rem;
    font-size: 1rem;
  }
 
  .required {
    color: red;
  }
/* EmpPassword.css */
 
/* Existing styles for input fields */
 
/* Button styles */
.button-container {
  position: relative;
  display: flex;
  justify-content: center;
}
 
button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
 
}
 
button:hover {
  background-color: #0056b3;
}
 
  /* Media queries for responsiveness */
 
  /* Mobile view */
  @media (max-width: 576px) {
    .form-container {
      flex-direction: column;
    }
  }
 
  /* Tablet view */
  @media (min-width: 577px) and (max-width: 768px) {
    .form-container {
      flex-direction: column;
    }
  }
 
  /* Laptop view */
  @media (min-width: 769px) and (max-width: 992px) {
    .form-container {
      flex-direction: row;
      flex-wrap: nowrap;
    }
  }
 
  /* Desktop view */
  @media (min-width: 993px) {
    .form-container {
      flex-direction: row;
      flex-wrap: nowrap;
    }
  }
  @media (max-width: 576px) {
    .button-container {
      width: 100%;
    }
  }
 
  /* Tablet and larger screens */
  @media (min-width: 577px) {
    .button-container {
      width: auto;
    }
  }