/* PayHeader.module.css */

.container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  gap: 20px;
}

.card {
  background: rgb(244, 241, 241);
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s, box-shadow 0.3s;
}

.card:hover,
.card.active {
  transform: translateY(-10px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
}

.cardContent {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.card p {
  font-size: 18px;
  color: #444;
  margin: 0;
}

.card h4 {
  font-size: 26px;
  color: #111;
  margin: 0;
}

.card h3 {
  font-size: 22px;
  color: #222;
  margin-bottom: 10px;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}

.row:last-child {
  border-bottom: none;
}

.component {
  font-size: 18px;
  color: #333;
  margin: 0;
}

.components div {
  display: flex;
  flex-direction: column;
}

.filters {
  display: flex;
  gap: 10px;
}

.filters select {
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.filters select option {
  font-size: 14px;
}
