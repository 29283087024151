body {
  margin: 0px;
  background-color: #fff;
  color: #0b0b15;
  font-family: 'Montserrat', sans-serif;
}
.emp-icon {
  vertical-align: middle;
  line-height: 1px;
  font-size: 20px;
  cursor: pointer;
}
.emp-icon_1 {
  height: 20px;
}
.emp-icon_header {
  vertical-align: middle;
  line-height: 1px;
  font-size: 26px;
}
.emp-icon, .icon_header {
  margin-right: 5px;
}
.emp-close_icon {
  color: red;
  margin-left: 30px;
  margin-top: 10px;
  cursor: pointer;
}
.emp-grid-container {
  display: grid;
  grid-template-columns: 260px 1fr 1fr 1fr;
  grid-template-rows: 0.2fr 3fr;
  grid-template-areas:
    'header header header header'
    'main main main main';
  height: 100vh;
}

.super-stickyCell {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: white;
  border-right: 2px solid lightblue;
}

 @media only screen and (min-width:300px ) and ( max-width:1440px){
  
     .empLabel{
       font-size: 20px !important;
       font-weight: 700 !important;
     }
     .form-control{
       width: 100%;
       height: auto;
     }
    
   }

@media screen and (max-width: 992px) {
  .grid-container {
    grid-template-columns: 1fr;
    grid-template-rows: 0.2fr 3fr;
    grid-template-areas:  
      'header'
      'main';
  }

  #sidebar {
    display: none;
  }

  .menu-icon {
    display: inline;
    
  }

  .sidebar-title > span {
    display: inline;
  }

  
}

  
.custom-menu {
  overflow: hidden !important;
}