.login-bg {
  background-image: url("./images/i-4-1.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-page {
  background-color: #f7e1b2;
  padding: 40px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 50%;
  left: 30%;
  transform: translate(-50%, -50%);
  opacity: 0.8;
}
 
.login-page h2 {
  justify-content: center;
  display: flex;
  margin-bottom: 30px;
}
 
.input-container {
  position: relative;
  margin-bottom: 20px;
  color: black;
}
 
 
.custom-input {
  margin: 5px 0;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
 
.input-container input {
  display: block;
  width: 100%;
  height: 42px;
}
 
.input-container label {
  margin-top: 5px;
  color: black;
}
 
.login-btn {
  padding: 10px 20px;
  background-color: #f5f3f1;
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.3s;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
 
}
 
.login-btn:hover {
  transform: scale(1.1);
  background-color: #e6c195;
}
 
.password-input {
  position: relative;
}
 
.password-input input {
  padding-right: 40px;
}
 
.password-input .toggle-password {
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  border: none;
  background: none;
  cursor: pointer;
  color: black;
  background-color: transparent;
  font-size: 20px;
}
 
.loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
 
.loader {
  width: 70px;
  height: 70px;
  position: relative;
}
 
.loader:before {
  content: "";
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 6px solid #007bff;
  position: absolute;
  top: 0;
  left: 0;
  animation: pulse 1s ease-in-out infinite;
}
 
.loader:after {
  content: "";
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 6px solid transparent;
  border-top-color: #007bff;
  position: absolute;
  top: 0;
  left: 0;
  animation: spin 2s linear infinite;
}
 
.loader-text {
  font-size: 24px;
  margin-top: 20px;
  color: #007bff;
  font-family: Arial, sans-serif;
  text-align: center;
  text-transform: uppercase;
}
 
@keyframes pulse {
  0% {
    transform: scale(0.6);
    opacity: 1;
  }
  50% {
    transform: scale(1.2);
    opacity: 0;
  }
  100% {
    transform: scale(0.6);
    opacity: 1;
  }
}
 
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
 
.content {
  display: none;
}
 
.loaded .loader-container {
  display: none;
}
 
.loaded .content {
  display: block;
}
 
@media screen and (max-width: 600px) {
  .login-page {
    /* padding: 20px; */
    position: absolute;
    top: 50%;
    left: 50%;
    width: 300px;
  }
 
  .input-container input {
    width: calc(100% - 20px);
  }
 
  .login-page h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
 
  button {
    padding: 8px 16px;
  }
  .password-input .toggle-password {
    position: absolute;
    top: 4%;
    right: 12px;
    transform: translateY(-50%);
    border: none;
    background: none;
    cursor: pointer;
    color: black;
    background-color: transparent;
    font-size: 20px;
  }
}


.forgot-password {
  margin-bottom: 20px;
  margin-left: 110px;
}

.forgot-password a {
  color: #666; 
  text-decoration: none; 
}

.forgot-password a:hover {
  text-decoration: underline; 
}

.custom-input.hide {
  color: transparent;
  text-security: disc;
  -webkit-text-security: disc;
  -moz-text-security: disc;
}

.custom-input.show {
  color: black;
  -webkit-text-security: none;
  -moz-text-security: none;
  text-security: none;
}

#password {
  color: black;
}
