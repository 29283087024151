.welcome-message {
  position: fixed;
  top: 70px;
  right: 10px;
  background: linear-gradient(90deg, #4caf50 0%, #81c784 100%);
  color: white;
  padding: 15px 25px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  font-family: 'Arial', sans-serif;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  letter-spacing: 1px;
  animation: fadeInOut 3s ease-in-out;
}
 
.welcome-message p {
  margin: 0;
}
/*  
@keyframes fadeInOut {
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }
  10% {
    opacity: 1;
    transform: translateY(0);
  }
  90% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-30px);
  }
} */
 
 