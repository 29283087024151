.alien {
    display: flex;
    justify-content: space-between;
}
.mars {
    margin-bottom: 20px;
}
.moon {
    margin-bottom: 10px;
}

@media (300px <= width <= 600px) {
    .alien {
        display: block;
    }
    .moon {
        margin-bottom: 20px;
        margin-left: 10px;
    }
}