body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: Arial, sans-serif;
}

.emp-profile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f9f9f9;
}

.emp-profile-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 80%;
  display: flex;
  flex-direction: row;
  padding: 20px;
  margin-bottom: 20px;
  transition: box-shadow 0.3s ease;
}

.emp-profile-card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.emp-profile-info {
  flex: 2;
  padding: 20px;
}

.emp-profile-info h3 {
  margin-bottom: 20px;
}

.emp-profile-info p {
  margin: 20px 0;
  display: flex;
  align-items: center;
}

.emp-profile-info p i {
  margin-right: 10px;
}

.emp-profile-details {
  flex: 3;
  padding: 30px;  
}

.emp-profile-section {
  margin-bottom: 20px; 
  flex: 1;
}

.emp-profile-section h3 {
  margin-bottom: 10px;
}

.emp-profile-buttons {
  display: flex;
  gap: 10px;
}

.emp-profile-buttons button {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.edit-btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.edit-btns {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 80px;
  height: 40px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.modal-content h3 {
  margin-top: 0;
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.save-btn, .cancel-btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.emp-profile-section ul {
  list-style-type: disc;
  padding-left: 20px;
}

.emp-profile-section ul li {
  margin-bottom: 5px;
}

.email-icon {
  color: rgb(217, 44, 151);
}

.email-text {
  color: rgb(217, 44, 151);
}


@media (max-width: 768px) {
  .emp-profile-card {
    flex-direction: column;
    width: 100%;
    padding: 15px;
  }

  .emp-profile-info, .emp-profile-details {
    padding: 10px;
  }

  .emp-profile-info h3, .emp-profile-info p, .emp-profile-section h3, .emp-profile-section p {
    margin: 10px 0;
  }

  .team-info-btn, .emp-profile-buttons button {
    padding: 10px;
    font-size: 14px;
  }

  .edit-btn {
    padding: 5px;
    font-size: 14px;
  }

  .modal-content {
    width: 80%;
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .emp-profile-card {
    padding: 10px;
  }

  .emp-profile-info h3, .emp-profile-info p, .emp-profile-section h3, .emp-profile-section p {
    margin: 5px 0;
  }

  .team-info-btn, .emp-profile-buttons button {
    padding: 8px;
    font-size: 12px;
  }

  .edit-btn {
    padding: 5px;
    font-size: 12px;
  }

  .modal-content {
    width: 90%;
    padding: 10px;
  }
}
